.bekliyorMesaji {
    position: relative;
    color: red;
    font-weight: bold !important;
    font-size: 3em;
    font-style: italic;
    height: auto;
    display: grid;
    grid-area: bekliyorMesaji;
    align-self: end;
    justify-self: center;
}

.fixedContainer {
    position: relative;
    display: grid;
    grid-area: fixedContainer;
    align-self: start;
    justify-self: center;
    flex-grow: 1;
}


.bekliyorCls {
    position: relative;
    color: red;
    height: 5rem;
    width: 5rem;
    margin: auto auto;
    margin-bottom: 0;
    animation: dondurdondur 3s linear infinite;
}

.centerHorizontalAndVerticalCls {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    grid-template-areas: "bekliyorMesaji" "fixedContainer";
    row-gap: 1rem;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    margin: 0 0;
    z-index: 1000;
}

.fullScreenCls {
    position: absolute;
    background-color: rgba(0, 0, 100, 0.75);
    color: white;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    margin: 0 0;
    z-index: 980;
}

.alignBottomGridCls {
    align-self: end;
}

@keyframes dondurdondur {
    to {
        transform: rotate(360deg);
    }
}