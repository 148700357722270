.demoDivCls {
    display: inline-block;
    /* padding: 0 1rem; */
    color: var(--colorSecondary1-5);
    grid-area: demoDivCls;
}

@media only screen and (max-width: 720px){
    .demoDivCls {
        font-size: x-small;
    }
}