.relativePosCls {
    position: relative;
}

.relativePosCls span {
    position: relative;
    display: inline-block;
    color: var(--colorComplementary3);
    /* background: linear-gradient(to left, var(--colorPrimaryWhite) 0%, var(--colorPrimaryWhite) 25%, var(--colorPrimaryRed) 25%, var(--colorPrimaryRed) 100%); */
    /* background-clip: text; */
}