.videoOdasiAnaKapsayicisiCls {
    display: grid;
    grid-template-areas:
    "videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi videoKapsayicisi"
    "sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici sohbetKutusuKapsayici";
    grid-area: videoOdasiAnaKapsayicisi;
    grid-template-rows: 1fr auto
}
.videoKapsayicisiCls {
    display: grid;
    grid-area: videoKapsayicisi;
}