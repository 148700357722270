* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root {
    /* 30D5C8 Turquoise - Turkuaz */
    /* complementary */
    --colorPrimary1:#85ECE4;
    --colorPrimary2:#55E0D6;
    --colorPrimary3:#30D5C9;
    --colorPrimary4:#0CC9BB;
    --colorPrimary5:#00A094;
    --colorSecondary1-1:#91B1EF;
    --colorSecondary1-2:#6590E4;
    --colorSecondary1-3:#4275DB;
    --colorSecondary1-4:#205BD0;
    --colorSecondary1-5:#0C42AC;
    --colorSecondary2-1:#FFD990;
    --colorSecondary2-2:#FFC961;
    --colorSecondary2-3:#FFBB39;
    --colorSecondary2-4:#FFAC0F;
    --colorSecondary2-5:#FFA700;
    --colorComplementary1:#FFC090;
    --colorComplementary2:#FFA661;
    --colorComplementary3:#FF8F39;
    --colorComplementary4:#FF770F;
    --colorComplementary5:#FF6F00;

    /*#85ECE4 based color;
    triple
    --colorPrimary2:#55E0D6;
    --colorPrimary3:#30D5C9;
    --colorPrimary4:#0CC9BB;
    --colorPrimary5:#00A094;
    --colorSecondary1-1:#FFD990;
    --colorSecondary1-2:#FFC961;
    --colorSecondary1-3:#FFBB39;
    --colorSecondary1-4:#FFAC0F;
    --colorSecondary1-5:#FFA700;
    --colorSecondary2-1:#FE9095;
    --colorSecondary2-2:#FE6168;
    --colorSecondary2-3:#FD3943;
    --colorSecondary2-4:#FD0F1B;
    --colorSecondary2-5:#FB000D;
    --colorPrimary1:#10C0D9; eskisi
    --colorPrimary2:#01BFD9;
    --colorPrimary3:#018497;
    --colorPrimary4:#006B7A; 
    --colorPrimary5:#00515C;
    --colorSecondary1-1:#264ADF;
    --colorSecondary1-2:#0832E0;
    --colorSecondary1-3:#0525A8;
    --colorSecondary1-4:#031D87;
    --colorSecondary1-5:#021666;
    --colorSecondary2-1:#FFB70E;
    --colorSecondary2-2:#FFB300;
    --colorSecondary2-3:#F6AD00;
    --colorSecondary2-4:#C78B00;
    --colorSecondary2-5:#966900;

    --colorComplementaryPrimary1:#FF820E;
    --colorComplementaryPrimary2:#FF7A00;
    --colorComplementaryPrimary3:#F67600;
    --colorComplementaryPrimary4:#C75F00;
    --colorComplementaryPrimary5:#964800; */
    
    /*#968072 based color; */
    /* complementary */
    /* --colorPrimary1:#E7D8CF;
    --colorPrimary2:#BBA79A;
    --colorPrimary3:#968072;
    --colorPrimary4:#6D5A4D;
    --colorPrimary5:#6D5A4D;
    --colorSecondary1-1:#E7DECF;
    --colorSecondary1-2:#BBAF9A;
    --colorSecondary1-3:#968972;
    --colorSecondary1-4:#6D614D;
    --colorSecondary1-5:#53442B;
    --colorSecondary2-1:#8A9099;
    --colorSecondary2-2:#676F7B;
    --colorSecondary2-3:#676F7B;
    --colorSecondary2-4:#353C48;
    --colorSecondary2-5:#1E2836;
    
    --colorComplementaryPrimary1:#849491;
    --colorComplementaryPrimary2:#627773;
    --colorComplementaryPrimary3:#49605B;
    --colorComplementaryPrimary4:#314642;
    --colorComplementaryPrimary5:#1B3530; */

    /*#FA8072 (salmon) based color; */
    /* complementary */
    /*--colorPrimary1:#FFC9C3;
    --colorPrimary2:#FFA39A;
    --colorPrimary3:#FA7F72;
    --colorPrimary4:#D95548;
    --colorPrimary5:#B73325;
    --colorSecondary1-1:#FFE0C3;
    --colorSecondary1-2:#FFCB9A;
    --colorSecondary1-3:#FAB472;
    --colorSecondary1-4:#D98F48;
    --colorSecondary1-5:#B76C25;
    --colorSecondary2-1:#ABD6DE;
    --colorSecondary2-2:#73B1BD;
    --colorSecondary2-3:#488D9A;
    --colorSecondary2-4:#2E7886;
    --colorSecondary2-5:#196371;
    
    --colorComplementaryPrimary1:#B3EABF;
    --colorComplementaryPrimary2:#80D593;
    --colorComplementaryPrimary3:#56BD6D;
    --colorComplementaryPrimary4:#36A44E;
    --colorComplementaryPrimary5:#1C8B34;*/

    --colorPrimaryWhite:#FFFFFF;
    --colorPrimaryRed:#FF0000;
    --colorLightGold:#FF9800 ;
    --colorBlack:#000000;
    --colorSalmon: salmon;

    --transitionLength: 0.4s;
    --transitionTiming: ease;
}

html {
    overflow: hidden;
    /* aşağıdaki satır renkleri tersine çeviriyor */
    /* filter: invert(100%); */
    /* aşağıdaki satır renkerli karartıyor */
    /* filter: brightness(50%); */
    /* aşağıdaki satır rengi tersine çeviriyor. Ancak benim durumumda renkler hoş olmadı */
    /* filter: invert(100%) hue-rotate(120deg); */
    /* filter: invert(100%) hue-rotate(180deg); */
    /* display: grid; */
    /* min-height: 100vh; */
    /* background-color: red !important;  */
}

body {
    background-color: var(--colorPrimary1);
    /* background-color: var(--colorSalmon); */
    color: var(--colorPrimaryWhite);
    /* color: salmon; */
}

img {
    width: 100%;
}

main {
    overflow: auto;
    scrollbar-width: none;
}

#mainBodyId {
    display: grid;
    /* grid-template-columns: repeat(12, 1fr); */
    grid-template-areas: 
    "headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls"
    "homePage homePage homePage homePage homePage homePage homePage homePage homePage homePage homePage homePage"
    "footer footer footer footer footer footer footer footer footer footer footer footer";
    grid-template-rows: min-content 1fr min-content;
    margin: 0 auto;
    /* min-height: 100%; */
    height: 100vh;
    overflow: auto;
    scrollbar-width: none;;
}

.homePage {
    width: 100vw;
    display: grid;
    grid-area: homePage;
    row-gap: 0.5em;
    grid-template-areas: 
    "mainSection mainSection mainSection mainSection mainSection mainSection mainSection mainSection mainSection mainSection mainSection mainSection";
    color: var(--colorSecondary1-5);
    overflow: auto;
    scrollbar-width: none;
}

.headerCls {
    display: grid;
    grid-template-areas: 
    "navbar navbar navbar navbar navbar navbar navbar navbar navbar navbar navbar navbar";
    grid-area: headerCls;
    grid-template-rows: minmax(50px, auto);
    padding: 1rem 0;
}

.mainSection {
    display: grid;
    grid-area: mainSection;
    align-content: start;
    /* grid-template-columns: 100%; */
    grid-template-areas: 
    "kirintilar kirintilar kirintilar kirintilar kirintilar kirintilar kirintilar kirintilar kirintilar kirintilar kirintilar kirintilar"
    "demoDivCls demoDivCls demoDivCls demoDivCls demoDivCls demoDivCls demoDivCls demoDivCls demoDivCls demoDivCls demoDivCls demoDivCls"
    "cikisKismi cikisKismi cikisKismi cikisKismi cikisKismi cikisKismi cikisKismi cikisKismi cikisKismi cikisKismi cikisKismi cikisKismi"
    ;
    justify-content: center;
    grid-template-rows: auto auto 1fr;
    grid-gap: 1rem;
    margin: 0 1rem;
}

.cikisKismiCls {
    display: grid;
    grid-area: cikisKismi;
    grid-template-areas: 
    "videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi videoOdasiAnaKapsayicisi"
    ;
}

footer {
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-area: footer;
    grid-template-areas: 
    "footerDiv footerDiv footerDiv footerDiv footerDiv footerDiv footerDiv footerDiv footerDiv footerDiv footerDiv footerDiv";
    background-color: var(--colorPrimary5);
}

a {
    text-decoration: none;
    color: var(--colorPrimaryWhite);
}

a:hover {
    color: var(--colorLightGold);
}

.redspanCls {
    color: var(--colorPrimaryRed) !important;
}

.greenspanCls {
    color: forestgreen !important;
}

.greenBackgroundCls {
    color: black;
    background-color: var(--colorPrimary4) !important;
    border: none;
}

.roundedCornersCls {
    border-radius: 1rem;
}

.greenBackgroundCls button {
    cursor: pointer !important;
}

button {
    height: 1.5rem;
}

.yellowBackgroundCls {
    color: black;
    background-color: yellow !important;
    border: none;
}

.redBackgroundCls {
    color: white;
    background-color: red !important;
    border: none;
}

.redBackgroundCls button {
    cursor: pointer !important;
}

.paddingSides5Rem {
    padding: 0% 5rem;
}

.marginSides5Rem {
    margin: 0% 5rem;
}

.hideItCls {
    display: none;
}

.hataMesajiCls {
    background-color: grey;
}

.gecerliCls {
    color: #01BFD9;
}

.ekranDisiCls {
    position: absolute;
    left: -150%;
    top: -150%;
}

.formCls {
    background-color: var(--colorSecondary1-5);
    color: var(--colorPrimaryWhite);
}

.formGridCls {
    display: grid;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: var(--colorSecondary1-5);
    color: var(--colorPrimaryWhite);
}

.formControlGridCls {
    display: grid;
    grid-template-columns: minmax(15rem, auto) 1fr;
    grid-template-areas: "label input";
    width: 100%;
    height: auto;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.formControlCls {
    display: block;
    width: 100%;
    height: auto;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.centerCls {
    row-gap: 0.5rem;
    margin: 0 auto;
    /* padding: 0 1rem; */
}

.centerTextCls {
    text-align: center;
    /* padding: 0 1rem; */
}

@media only screen and (max-width: 720px){
    footer {
        position: fixed;
        bottom: 0;
    }

    /* .footerDiv {
        height: fit-content;
    } */

    #mainBodyId {
        display: grid;
        grid-template-areas: 
        "headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls headerCls"
        "homePage homePage homePage homePage homePage homePage homePage homePage homePage homePage homePage homePage"
        "footer footer footer footer footer footer footer footer footer footer footer footer";
        grid-template-rows: min-content 1fr min-content;
    }

    .headerCls {
        display: grid;
        /* grid-template-rows: max-content; */
        grid-template-areas:
        "navbar navbar navbar navbar navbar navbar navbar navbar navbar navbar navbar navbar";
        background-color: var(--colorPrimary5);
        width: 100vw;
    }

    .mainSection {
        display: grid;
        grid-area: mainSection;
    }

    .formControlGridCls {
        grid-template-rows: auto 1fr;
        grid-template-areas: "label" "input";
        height: auto;
    }
}