.accordionContainerCls {
    position: relative;
    bottom: 0;
    /* margin: 0 auto; */
    padding: 0.5em;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    overflow: auto;
    scrollbar-width: none;
}

.accordionItemCls {
    width: 100%;
    margin-bottom: 1em;
    border: 1px solid var(--colorSecondary1-4);
}

.paddingLeft1RemCls {
    padding-left: 1rem;
}

.paddingLeft05RemCls {
    padding-left: 0.5rem;
}

.paddingRight05RemCls {
    padding-right: 0.5rem;
}

.accordionItemCls label {
    cursor: pointer;
    margin-bottom: 1rem;
}

.accordion-tetiki {
    background-color: var(--colorComplementary2);
}

.accordion-tetiki-alt{
    background-color: var(--colorComplementary4);
}

.accordionItem-tetik-girisi {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}

.accordionItem-tetik-girisi:checked ~ .accordion-animasyon-sarici {
    grid-template-rows: 1fr;
}

.accordionItem-tetik-girisi:checked ~ .accordion-animasyon-sarici .accordion-donusum-sarici {
    transform: translateY(0);
    visibility: visible;
    transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s linear;
}

.accordion-animasyon-sarici {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    transition: grid-template-rows var(--transitionLength) var(--transitionTiming);
}

.accordion-animasyonu {
    min-height: 0;
    /* padding-bottom: 1rem; */
}

.accordion-donusum-sarici {
    transform: translateY(-100%);
    visibility: hidden;
    transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming);
}

.accordion-icerigi-alt * {
    margin: 0;
    background-color: var(--colorSecondary1-1);
}
.accordion-icerigi-alt * {
    margin: 0;
    background-color: var(--colorSecondary1-2);
}

.accordionBtnCls {
    /* display: block; */
    cursor: pointer;
    width: 100%;
}

.accordionBtnCls:hover div {
    display: grid;
    background-color: var(--colorComplementaryPrimary2);
}

.accordionBodyCls {
    display: none;
}