#myCookieConsent {
    z-index: 999;
    min-height: 20px;
    font-family: OpenSans, arial, "sans-serif";
    padding: 10px 20px;
    margin: 0% 5rem;
    background: rgba(0,0,0,0.6);
    position: fixed;
    color: #FFF;
    background-color: var(--colorSecondary1-5);
    bottom: 0px;
    right: 10px;
    left: 10px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}