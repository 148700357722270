.allowedCharactersCls p span {
    color: red;
    display: inline-block;
    margin: 0 0.5em;
    animation: ziplamaAnimasyonu 7s ease-in-out infinite;
}

.allowedCharactersCls p span:nth-child(1) {
    animation-delay: 1s;
}

.allowedCharactersCls p span:nth-child(2) {
    animation-delay: 2s;
}

.allowedCharactersCls p span:nth-child(3) {
    animation-delay: 3s;
}

.allowedCharactersCls p span:nth-child(4) {
    animation-delay: 4s;
}

.allowedCharactersCls p span:nth-child(5) {
    animation-delay: 5s;
}

.allowedCharactersCls p span:nth-child(6) {
    animation-delay: 6s;
}

.allowedCharactersCls p span:nth-child(7) {
    animation-delay: 7s;
}

@keyframes ziplamaAnimasyonu {
    0% {
        /* transform: translateY(3px); */
        transform: scale(1);
        color: yellow;
    }
    25% {
        /* transform: translateY(2px); */
        transform: scale(2);
    }
    50% {
        /* transform: translateY(-2px); */
        transform: scale(0.5);
    }
    100% {
        /* transform: translateY(-3px); */
        transform: scale(0.5);
        color: red;
    }
}