.kirintilarCls {
    display: block;
    height: fit-content;
    /* padding-left: 1rem; */
    width: 100vw;
    grid-area: kirintilar;
}
.kirintilarCls > * {
    color: var(--colorBlack);
    display: inline-block;
}