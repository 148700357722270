.sohbetKutusuKapsayiciCls {
    display: grid;
    width: 100vw;
    grid-area: sohbetKutusuKapsayici;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "label input";
    /* justify-self: self-end; */
}

.sendBtnCls {
    cursor: pointer;
}