.footerDiv {
    display: grid;
    grid-area: footerDiv;
    grid-template-areas: 
    "footerItems sagAltKoseCls";
}

/* .footerDiv * {
    color: var(--colorSecondary1-5);
} */

.footerItems {
    display: grid;
    grid-area: footerItems;
    align-items: center;
}