nav {
    position: absolute;
    top: 0;
    width: 100vw;
    display: grid;
    grid-template-columns: minmax(15rem, auto) 1fr auto minmax(15rem, auto);
    grid-template-rows: minmax(50px, auto);
    grid-template-areas: "navLeftSideCls navAboutCls navLoginCls navLanguageCls";
    background-color: var(--colorPrimary5);
    grid-area: navbar;
    z-index: 970;
}

a {
    margin-left: 0.5rem;
}

.visibleCls {
    visibility: visible;
    display: block;
}

.invisibleCls {
    display: none;
    visibility: hidden;
}

.navLeftSideCls {
    display: grid;
    grid-area: navLeftSideCls;
}

.navLeftSideCls a {
    width: max-content;
}

.navAboutCls {
    display: grid;
    grid-area: navAboutCls;
}

.aboutUs {
    width: max-content;
}

.navLoginCls {
    display: grid;
    width: max-content;
    grid-template-columns: minmax(min-content, 10rem);
    /* grid-auto-rows: min-content; */
    /* white-space: nowrap; */
    grid-area: navLoginCls;
    justify-self: end;
    margin-right: 1rem;
}

.navLanguageCls {
    display: grid;
    grid-template-columns: minmax(min-content, 5rem);
    grid-area: navLanguageCls;
    justify-self: end;
    margin-right: 1rem;
}

.nav-btn {
    display: grid;
    grid-area: nav-btn;
}

nav .nav-btn {
    cursor: pointer;
    visibility: hidden;
    display: none;
}

.logoCls {
    padding-left: 1rem;
}

.navBarDropDownCls {
    position: relative;
    list-style-type: none;
}

.navBarDropDownLiContainerCls {
    display: grid;
}

.navBarDropDownCls ul {
    display: none;
    list-style-type: none;
    height: 0;
}

.navBarDropDownCls a {
    width: max-content;
}

.navBarDropDownCls a:hover {
    background-color: var(--colorPrimary5);
}

.navBarDropDownCls:hover li> ul {
    display: inline-block;
    width: max-content;
    list-style-type: none;
    height: auto;
}

.logInCls {
    position: relative;
}

.headerContainerCls .nav-btn {
    cursor: pointer;
    visibility: hidden;
    display: none;
}

@media only screen and (max-width: 720px) {
    header nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        transition: 1s;
    }
    nav .nav-btn {
        display: grid;
        position: relative;
        height: fit-content;
        width: fit-content;
        justify-self: end;
        cursor: pointer;
        visibility: visible;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }
    nav {
        display: grid;
        grid-template-columns: minmax(250px, auto) 1fr;
        grid-template-areas:
        "navLeftSideCls nav-btn" ;
        grid-area: navbar;
        grid-auto-rows: max-content;
        left: 0;
    }
    .visibleCls {
        display: block;
        visibility: visible;
    }
    .navOpened {
        display: grid;
        grid-template-columns: minmax(250px, auto) 1fr;
        grid-template-areas:
        "navLeftSideCls nav-btn"
        "navAboutCls navAboutCls"
        "navLoginCls navLoginCls"
        "navLanguageCls navLanguageCls" ;
        grid-area: navOpened;
        height: auto;
    }
    .showInlineBlockCls {
        display: inline-block !important;
    }
    .navAboutCls {
        display: none;
    }
    .visibleCls.navAboutCls {
        display: grid;
        grid-area: navAboutCls;
    }
    /*.navBarDropDownCls ul {
        display: none;
        list-style-type: none;
        height: 0;
    }*/
    .navBarDropDownCls>li:hover + ul {
        display: inline-block;
        list-style-type: none;
        height: 100px;
    }
    .navLoginCls {
        display: none;
    }
    .visibleCls.navLoginCls {
        display: grid;
        grid-area: navLoginCls;
        justify-self: start;
    }
    .navLeftSideCls a {
        width: max-content;
        height: max-content;
    }
    .navLanguageCls {
        display: none;
        justify-self: start;
    }
    .visibleCls.navLanguageCls {
        display: grid;
        grid-area: navLanguageCls;
        justify-self: start;
        visibility: visible;
    }
}