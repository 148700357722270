.mobileAppItem {
    grid-area: "mobileAppItem";
    position: absolute;
    display: inline-block;
    top: 0px;
    height: 0;
    width: 0;
    overflow: hidden;
    /* animation-name: mobileAppsAmimation;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction:normal;
    animation-timing-function: ease-in-out; */
}
.mobileAppItem:hover {
    animation-play-state: paused;
    transform: none;
}
.mobileAppItem:nth-child(2){
    animation-delay: 1s;
}
@keyframes mobileAppsAmimation {
    0% {
        /* opacity: 0.1; */
        height: auto;
        width: auto;
        transform: translateY(500px)
    }
    50% {
        /* opacity: 0.1; */
        transform: translateY(10px)
    }
    99% {
        opacity: 1;
        transform: translateY(50px)
    }
    100% {
        /* opacity: 0; */
        height: 0;
        width: 0;
        transform: translateY(500px)
    }
}
